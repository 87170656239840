import axios from 'axios'
import Qs from 'qs'

export default {
  users(groups) {
    return axios
      .get('/api/super_admin/user_groups/users', {
        params: {
          user_groups: {
            group_ids: groups.map(group => group.id)
          }
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets'
          })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
