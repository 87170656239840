<template>
  <div class="row">
    <div class="col-sm-12">
      <b-card
        class="std-border"
        body-class="p-1 activity-filter-form"
        header-class="p-2"
      >
        <div slot="header">
          <div class="row fit-to-center">
            <div class="col-sm-12">
              <div class="d-flex">
                <h5 class="mb-0 font-weight-bold">
                  Query Builder
                </h5>
                <h6 class="mb-0 px-2 d-flex align-self-center">
                  (Use below options to filter user order details)
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="p-3">
          <div class="row">
            <div class="col-sm-6">
              <div v-if="fetchingGroups">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="9"
                  ></content-placeholders-text>
                </content-placeholders>
              </div>
              <div v-else>
                <b-form-group
                  label="Group"
                  label-for="group"
                  :label-cols="3"
                  :horizontal="true"
                  class=""
                >
                  <multiselect
                    v-model="groups"
                    :options="groupsList"
                    :multiple="true"
                    :close-on-select="false"
                    :showLabels="false"
                    track-by="id"
                    label="name"
                    :placeholder="groups.length ? '' : 'Select Groups'"
                    :class="[
                      {
                        'is-invalid': $v.groups.$error,
                        'is-valid': !$v.groups.$invalid
                      },
                      'simple-select group-select highlight-group-title form-control rounded'
                    ]"
                    @close="getGroupUsers"
                  >
                    <template
                      slot="selection"
                      v-if="groups.length"
                      slot-scope="{ values }"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('group') }} selected
                    </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="!$v.groups.required"
                    >at least one group required</b-form-invalid-feedback
                  >
                </b-form-group>

                <b-form-group
                  label="User"
                  label-for="user"
                  :label-cols="3"
                  :horizontal="true"
                  class=""
                >
                  <multiselect
                    v-model="users"
                    :options="usersList"
                    :multiple="true"
                    :close-on-select="false"
                    :showLabels="false"
                    :loading="fetchingUsers"
                    track-by="id"
                    label="name"
                    :placeholder="groups.length ? '' : 'Select Users'"
                    :class="[
                      {
                        'is-invalid': $v.users.$error,
                        'is-valid': !$v.users.$invalid
                      },
                      'simple-select group-select highlight-group-title form-control rounded'
                    ]"
                    @close="validateUsers"
                  >
                    <template
                      slot="selection"
                      v-if="users.length"
                      slot-scope="{ values }"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('user') }} selected
                    </template>
                    <template slot="beforeList" v-if="usersList.length">
                      <li class="multiselect__elemet p-2 border-bottom">
                        <span
                          @click="selectOrRemoveAllUsers()"
                          class="select-all-option"
                        >
                          <b>{{ selectAllText }}</b></span
                        >
                      </li>
                    </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="!$v.users.required"
                    >at least one user required</b-form-invalid-feedback
                  >
                </b-form-group>

                <b-form-group
                  label="Order Type"
                  label-for="order_type"
                  :label-cols="3"
                  :horizontal="true"
                  class=""
                >
                  <multiselect
                    v-model="orderTypes"
                    :options="orderTypesList"
                    :multiple="true"
                    :close-on-select="false"
                    :showLabels="false"
                    :placeholder="
                      orderTypes.length ? '' : 'Select Report Types'
                    "
                    track-by="value"
                    label="text"
                    :class="[
                      {
                        'is-invalid': $v.orderTypes.$error,
                        'is-valid': !$v.orderTypes.$invalid
                      },
                      'simple-select group-select highlight-group-title form-control rounded'
                    ]"
                  >
                    <template
                      slot="selection"
                      v-if="orderTypes.length"
                      slot-scope="{ values }"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('type') }} selected
                    </template>
                  </multiselect>
                  <b-form-invalid-feedback v-if="!$v.orderTypes.required"
                    >at least one report type required</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group
                  label="Date Range"
                  label-for="date_range"
                  :label-cols="3"
                  :horizontal="true"
                  class=""
                >
                  <div class="row">
                    <div
                      class="col-sm-6 date-col"
                      :class="{
                        'is-invalid': $v.startDate.$error,
                        'is-valid': !$v.startDate.$invalid
                      }"
                    >
                      <datepicker
                        v-model="startDate"
                        placeholder="Start Date"
                        format="yyyy-MM-dd"
                        :bootstrap-styling="true"
                        class="start-date-picker"
                        @selected="validateStartDate()"
                      ></datepicker>
                      <b-form-invalid-feedback v-if="!$v.startDate.required"
                        >can't be blank</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback v-if="!$v.startDate.isBefore"
                        >should be earlier than End
                        Date</b-form-invalid-feedback
                      >
                    </div>

                    <div
                      class="col-sm-6 date-col"
                      :class="{
                        'is-invalid': $v.endDate.$error,
                        'is-valid': !$v.endDate.$invalid
                      }"
                      @focusout="validateEndDate()"
                    >
                      <datepicker
                        v-model="endDate"
                        placeholder="End Date"
                        format="yyyy-MM-dd"
                        :bootstrap-styling="true"
                        class="start-date-picker"
                        @selected="validateEndDate()"
                      ></datepicker>
                      <b-form-invalid-feedback v-if="!$v.endDate.required"
                        >can't be blank</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback v-if="!$v.endDate.isBefore"
                        >should be after Start Date</b-form-invalid-feedback
                      >
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>

            <div class="col-sm-6 px-5" v-if="!isSuperAdmin">
              <b-card
                class="std-border my-3 rounded card--report-schedule"
                body-class="p-2 schedule-body"
                header-class="p-2 schedule-header"
              >
                <div slot="header">
                  <div class="row fit-to-center">
                    <div class="col-sm-12">
                      <b> Schedule Monthly Report </b>
                    </div>
                  </div>
                </div>
                <div v-if="updatingScheduledReport">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="5"
                    ></content-placeholders-text>
                  </content-placeholders>
                </div>
                <div v-else>
                  <div>
                    <p>
                      Enabling this feature will send a monthly user orders
                      report applying these filters(except date range) to your
                      registered email.
                      <br />
                      <b class="d-inline-block pt-2">Note:</b> Date range will
                      always between start date and end date of month for
                      scheduled reports.
                    </p>
                  </div>
                  <div class="text-center mt-2">
                    <button
                      type="submit"
                      class="btn btn-secondary rounded py-1 px-2 mt-1 fw-btn"
                      @click="disableScheduledReport"
                      :disabled="
                        !(scheduledReport && scheduledReport.enable_schedule)
                      "
                    >
                      Disable
                    </button>
                    <button
                      type="submit"
                      class="btn btn-success rounded py-1 px-2 mt-1 mx-3 btn-enable fw-btn"
                      @click="updateScheduledReport()"
                      v-if="scheduledReport && scheduledReport.enable_schedule"
                    >
                      <b>Update</b>
                    </button>
                    <button
                      type="submit"
                      class="btn btn-success rounded py-1 px-2 mt-1 mx-3 btn-enable fw-btn"
                      @click="enableScheduledReport()"
                      v-else
                    >
                      <b>Enable</b>
                    </button>
                  </div>
                </div>
              </b-card>
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-6 d-flex justify-content-center">
              <div class="px-1">
                <button
                  type="button"
                  class="btn fw-btn btn-secondary rounded"
                  @click="resetQuery"
                >
                  Reset
                </button>
              </div>
              <div class="px-1">
                <button
                  type="button"
                  class="btn fw-btn btn-primary rounded"
                  @click="fetchOrderReport"
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <b-card
        class="std-border my-3 card--purchase-history"
        body-class="p-1"
        header-class="p-2"
        v-if="showReports"
      >
        <div slot="header">
          <div class="row fit-to-center">
            <div class="col-sm-6">
              <div class="page-header-left">
                <div>
                  <h5 class="mb-0">
                    <b>Query Results</b>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="page-header-right">
                <div class="">
                  <i
                    class="cuis-cloud-download font-2xl download-icon"
                    title="Download CSV"
                    @click="downloadCSVReport()"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="fetchingReports">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </div>
        <b-table
          striped
          hover
          :fields="orderFields"
          :items="orderReports"
          small
          responsive
        >
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import * as moment from 'moment'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
import downloadsUtil from '@/modules/core/api/downloads'

export default {
  name: 'PurchaseHistory',
  components: {
    Multiselect,
    Datepicker
  },
  props: {
    role: {
      type: String,
      default: 'admin'
    },
    groupsList: {
      type: Array,
      default() {
        return []
      }
    },
    usersList: {
      type: Array,
      default() {
        return []
      }
    },
    orderReports: {
      type: Array,
      default() {
        return []
      }
    },
    fetchingGroups: {
      type: Boolean,
      default: false
    },
    fetchingUsers: {
      type: Boolean,
      default: false
    },
    fetchingReports: {
      type: Boolean,
      default: false
    },
    reportScheduled: {
      type: Boolean,
      default: false
    },
    scheduledReport: {
      type: Object,
      required: false
    },
    updatingScheduledReport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groups: [],
      users: [],
      orderTypes: [],
      endDate: null,
      startDate: null,
      showReports: false,
      orderTypesList: [
        { value: 'QueryDownload', text: 'FIN Query' },
        { value: 'OrderBank', text: 'FI Analytics Report' },
        { value: 'ProductThemeReport', text: 'Performographic List' },
        { value: 'BankServiceVendorReport', text: 'Technographic List' },
        { value: 'OrderBankService', text: 'FinTech Market Report' }
      ],
      orderFields: [
        { key: 'id', label: 'Order ID', sortable: true },
        { key: 'user_id', label: 'User ID', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'orderable_type', label: 'Order Type', sortable: true },
        { key: 'state', label: 'Status', sortable: true },
        { key: 'record_count', label: 'Count', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'created_at', label: 'Date', sortable: true },
        { key: 'card_holder_name', label: 'Card Holder Name', sortable: true },
        { key: 'card_type', label: 'Card Type', sortable: true },
        { key: 'card_ends_with', label: 'Card End No', sortable: true }
      ]
    }
  },
  computed: {
    isSuperAdmin() {
      return this.role === 'super_admin'
    },
    selectAllText() {
      return this.allUsersSelected ? 'Clear All' : 'Select All'
    },
    allUsersSelected() {
      return this.users.length === this.usersList.length
    },
    validForScheduledReport() {
      this.$v.$reset()
      this.$v.groups.$touch()
      this.$v.users.$touch()
      this.$v.orderTypes.$touch()

      return (
        !this.$v.groups.$error &&
        !this.$v.users.$error &&
        !this.$v.orderTypes.$error
      )
    }
  },
  methods: {
    getGroupUsers() {
      if (this.groups.length) {
        this.$emit('fetchGroupUsers', this.groups)
      }
    },
    selectOrRemoveAllUsers() {
      this.users = this.allUsersSelected ? [] : this.usersList
    },
    fetchOrderReport() {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.showReports = true
        this.$emit('fetchPurchaseHistory', {
          groups: this.groups,
          users: this.users,
          orderTypes: this.orderTypes,
          startDate: this.startDate,
          endDate: this.endDate
        })
      }
    },
    resetQuery() {
      this.groups = []
      this.users = []
      this.orderTypes = []
      this.startDate = null
      this.endDate = null
      this.userReports = []
      this.showReports = false
      this.$v.$reset()
    },
    validateUsers() {
      this.$v.users.$touch()
    },
    validateStartDate() {
      this.$v.startDate.$touch()
    },
    validateEndDate() {
      this.$v.endDate.$touch()
    },
    enableScheduledReport() {
      if (this.validForScheduledReport) {
        this.$emit('enableMonthlyReport', {
          groups: this.groups,
          users: this.users,
          orderTypes: this.orderTypes
        })
      } else {
        this.$toasted.show(
          'Please set mandatory field values for scheduled report delivery.',
          {
            icon: 'user-circle',
            type: 'error'
          }
        )
      }
    },
    updateScheduledReport() {
      if (this.validForScheduledReport) {
        this.$emit('updateMonthlyReport', {
          id: this.scheduledReport.id,
          groups: this.groups,
          users: this.users,
          orderTypes: this.orderTypes
        })
      } else {
        this.$toasted.show(
          'Please set mandatory field values for scheduled report delivery.',
          {
            icon: 'user-circle',
            type: 'error'
          }
        )
      }
    },
    disableScheduledReport() {
      this.$emit('deleteMonthlyReport', this.scheduledReport.id)
    },
    downloadCSVReport() {
      let fieldKeys = this.orderFields.map(field => field.key)
      let csv = this.orderFields.map(field => field.label).join(',') + '\n'

      this.orderReports.forEach(report => {
        let csvRow =
          fieldKeys
            .map(key => (report[key] ? `"${report[key]}"` : ''))
            .join(',') + '\n'
        csv += csvRow
      })

      downloadsUtil.downloadCSVResult(csv, 'user_orders_report.csv')
    }
  },
  validations: {
    groups: {
      required
    },
    users: {
      required
    },
    orderTypes: {
      required
    },
    startDate: {
      required,
      isBefore(date) {
        if (!date || !this.endDate) {
          return true
        }
        let formattedEndDate = moment(this.endDate)
        return moment(date).isBefore(formattedEndDate)
      }
    },
    endDate: {
      required,
      isAfter(date) {
        if (!date || !this.startDate) {
          return true
        }
        let formattedStartDate = moment(this.startDate)
        return moment(date).isAfter(formattedStartDate)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-report {
  font-size: 1.2rem;
}

.card--report-schedule {
  .schedule-header {
    background-color: #c8d3d9;
  }

  .schedule-body {
    background-color: #f0f3f5;
  }
}

.btn-enable {
  color: white;
}
</style>

<style lang="scss">
.activity-filter-form {
  .form-control[readonly] {
    background-color: white;
  }

  .start-date-picker,
  .end-date-picker {
    display: flex;
    flex-basis: inherit;
    padding: 0 0 !important;

    .input-group {
      border-radius: 5px;
    }
  }
}

.card--purchase-history {
  .download-icon {
    cursor: pointer;
  }
}

.date-col {
  .form-control {
    background-color: white;
    border-radius: 4px;
  }

  &.is-valid {
    .vdp-datepicker {
      .form-control {
        border-color: #79c447;
      }
    }

    .invalid-feedback {
      display: none;
    }
  }

  &.is-invalid {
    .vdp-datepicker {
      .form-control {
        border-color: #ff5454;
      }
    }

    .invalid-feedback {
      display: block;
    }
  }
}
</style>
