<template>
  <super-admin-page-card
    pageName="User Order Report"
    iconClass="fa fa-shopping-cart fa-lg"
  >
    <purchase-history
      role="super_admin"
      :groupsList="groupsList"
      :usersList="usersList"
      :orderReports="orderReports"
      :fetchingGroups="fetchingGroups"
      :fetchingUsers="fetchingUsers"
      :fetchingReports="fetchingReports"
      @fetchGroupUsers="getGroupUsers"
      @fetchPurchaseHistory="getPurchaseHistory"
    ></purchase-history>
  </super-admin-page-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'
// ui components
import UserPurchaseHistory from '@/shared/components/UserPurchaseHistory'
// api
import groupsAPI from '@/api/finapps/super_admin/groups'
import userGroupsAPI from '@/api/finapps/super_admin/user_groups'
import ordersAPI from '@/api/finapps/super_admin/orders'

export default {
  name: 'SuperAdminUserPurchaseHistory',
  components: {
    'purchase-history': UserPurchaseHistory
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/manageSuperAdminBoard']) {
      next()
    } else {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.getUserGroups()
  },
  data() {
    return {
      groupsList: [],
      usersList: [],
      orderReports: [],
      fetchingUsers: false,
      fetchingGroups: false,
      fetchingReports: false
    }
  },
  computed: {
    ...mapGetters('Ability', ['manageSuperAdminBoard'])
  },
  methods: {
    getUserGroups() {
      this.fetchingGroups = true
      groupsAPI.all().then(res => {
        this.groupsList = res.groups
        this.fetchingGroups = false
      })
    },
    getGroupUsers(groups) {
      this.fetchingUsers = true
      userGroupsAPI.users(groups).then(res => {
        this.usersList = res.users
        this.fetchingUsers = false
      })
    },
    getPurchaseHistory(options) {
      this.fetchingReports = true
      ordersAPI.user_orders(options).then(res => {
        this.orderReports = res.orders
        this.fetchingReports = false
      })
    }
  }
}
</script>
